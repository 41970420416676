<template>
    <div class="clientListContainer">
        <v-list
            two-line
            class="client-list"
        >
            <RecycleScroller
                class="scroller"
                :items="filteredClients"
                :item-size="73"
                key-field="client"
                v-slot="{ item }"
            >
                <v-list-item
                    :key="item.client"
                    class="user"
                >
                    <v-list-item-content
                        @click="showInfos(item)"
                    >
                        <v-list-item-title class ="textSize15 font-weight-bold">
                            <text-highlight :queries="searchClient">
                                {{ item.fullName}}
                            </text-highlight>
                        </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-list-item-subtitle class ="textSize12 font-weight-bold">
                            {{ item.client}}
                        </v-list-item-subtitle>
                        <v-btn
                            dark
                            fab
                            small
                            @click="selectClient(item)"
                        >
                            <v-icon>add</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
                <v-divider />
            </RecycleScroller>
            <v-dialog
                v-model="infoDialog"
            >
                <v-card>
                    <v-card-title
                        class="text-h5 grey darken-2 white--text font-weight-bold"
                        primary-title
                    >
                        {{ infos.fullName }}
                        <v-spacer />
                        <v-btn
                            icon
                            dark
                            @click="infoDialog = false"
                        >
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text
                        class="grey"
                    >
                        <v-list
                            dark
                            class="grey"
                        >
                            <v-list-item
                                v-for="(info, key) in infos"
                                :key="key"
                            >
                                <v-spacer />
                                <v-list-item>
                                    {{ key }}: {{ info }}
                                </v-list-item>
                            </v-list-item>

                        </v-list>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-list>
    </div>
</template>

<script>
import localforage from 'localforage'
import orderBy from 'lodash/orderBy'

export default {
    props: {
        searchClient: {
            type: String,
            default () {
                return ''
            }
        }
    },
    data () {
        return {
            infos: {},
            infoDialog: false,
            items: []
        }
    },
    computed: {
        filteredClients () {
            if (!this.searchClient) {
                return orderBy(this.items, ['client', 'fullName'])
            } else {
                return this.items.filter((item) => {
                    return item.fullName.toLowerCase().includes(this.searchClient.toLowerCase()) ||
                    item.client.toLowerCase().includes(this.searchClient.toLowerCase())
                })
            }
        }
    },
    created () {
        this.loadClient()
    },
    methods: {
        showInfos (item) {
            // show informations
            this.infos = item
            this.infoDialog = true
        },
        loadClient () {
            // load clients from localforage
            localforage.getItem('clients', (err, value) => {
                this.items = value
                if (err !== null) {
                    // eslint-disable-next-line
                    console.log(err)
                }
            })
        },
        selectClient (item) {
            localforage.setItem('selectedClient', item)
                .then(() => {
                    window.location = '#/summary'
                })
        }
    }
}
</script>
<style >
.client-list {
    flex-grow: 1;
    overflow: auto;
    padding: 0;
}
.clientList .searchBar {
    padding: 20px 40px 0px 10px;
}
.scroller {
  height: 100%;
}
.clientListContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
}

.textSize15 {
    font-size: 15px;
}
.textSize12 {
    font-size: 12px;
}
</style>
