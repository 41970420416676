<template>
    <div>
        <v-app
            id="inspire"
            class="ventesapp"
        >
            <v-layout
                class="clientContainer"
                column
                fill-height
            >
                <header>
                    <div class="gradient" />
                    <v-app-bar
                        color="black"
                        dark
                    >
                        <v-btn
                            icon
                            to="/"
                        >
                            <v-icon>arrow_back</v-icon>
                        </v-btn>
                        <span
                            v-if="!searchBar"
                            class="text-h6"
                        >
                            {{ $t("client.clients") }}
                        </span>
                        <v-text-field
                            v-if="searchBar"
                            :value="searchClient"
                            @input="onInput"
                            clearable
                            :placeholder='$t("client.search")'
                            class="searchBar"
                            ref="search"
                        />
                        <v-spacer />
                        <v-btn
                            icon
                            @click="openSearch"
                        >
                            <v-icon>search</v-icon>
                        </v-btn>
                    </v-app-bar>
                </header>
                <clientList
                    class="clientList"
                    :searchClient="searchClient"
                />
            </v-layout>
        </v-app>
    </div>
</template>
<script>
import clientList from '../components/clientList'
import _ from 'lodash'

export default {
    name: 'Clients',
    components: {
        clientList
    },
    data () {
        return {
            searchBar: false,
            searchClient: ''
        }
    },
    methods: {
        openSearch () {
            this.searchBar = true
            this.$nextTick(() => this.$refs.search.focus())
        },
        onInput: _.debounce(function (searchClient) {
            this.searchClient = searchClient
        }, 300)
    }
}
</script>
<style>
.clientContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.clientContainer header {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}
.clientContainer .clientList {
    overflow: auto;
    flex-grow: 1;
}
</style>
